<!-- <header>
    <div class="logo">
        <div class="bars"></div>
        <h2>ULTRA CODE</h2>
    </div>

    <nav>
        <ul class="nav-links">
            <li><a [routerLink]="'/services'"><i class="fa-solid fa-car-side"></i><span>Services</span></a></li>
            <li><a href="javascript: void(0)"><i class="fa-solid fa-phone-flip"></i><span>Contact</span></a></li>
        </ul>
    </nav>

    <section>
        <div class="pin-code">
            <app-pincode-input></app-pincode-input>
        </div>
        <div *ngIf="authS.loggedIn$ | async; else loginBtn" class="profile-container">
            <img src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" alt="profile image" (click)="dropdownVisible = !dropdownVisible"/>
            <div *ngIf="dropdownVisible" [@dropdownAnimation] class="dropdown-content" id="dropdown-content">
                <a [routerLink]="'/profile'" (click)="dropdownVisible=false">Profile</a>
                <button (click)="logout()" class="btn" type="button">Logout</button>
            </div>
        </div>
        <ng-template #loginBtn>
            <button (click)="login()" class="btn login-btn" type="button">Login</button>
        </ng-template>
    </section>
</header> -->

<header>
    <div class="left-side">
      <div class="logo">
        <img src="/assets/logos/logo1.png" alt="logo"/>
      </div>
      <nav>
        <a routerLink="'/'">Home</a>
        <a [routerLink]="'/services'">Services</a>
        <a>Help</a>
        <a>Blog</a>
      </nav>
    </div>
    <div class="right-side">
      <div class="input-field">
        <app-pincode-input></app-pincode-input>
      </div>
      <div class="profile">
        <button class="login-btn">LOGIN</button>
      </div>
    </div>
  </header>