import { Component, HostListener } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';  // Import necessary functions
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      transition(':enter', [
        style({ opacity: 0 }), // Start with opacity 0 (hidden)
        animate('50ms 0s', style({ opacity: 1 })) // Animate to opacity 1 (visible)
      ]),
      transition(':leave', [
        animate('50ms 0s', style({ opacity: 0 })) // Animate back to opacity 0 (hidden)
      ])
    ])
  ]
})
export class HeaderComponent {
  currIdx = 0;
  logos = ["logo1.png", "logo2.png", "logo3.png", "logo4.png", "logo5.png", "logo6.png"];
  logoUrl = "/assets/logos/"+this.logos[0];

  dropdownVisible: boolean = false;

  constructor(private readonly router: Router, public readonly authS: AuthService) { };

  ngOnInits() {
    setInterval(() => {
      this.logoUrl = "/assets/logos/" + this.logos[this.currIdx];
      this.currIdx++;
      if (this.currIdx == this.logos.length) {
        this.currIdx = 0;
      }
    }, 3000);
  }

  @HostListener('document:click', ['$event'])
  closeDropdownOnClickOutside(event: MouseEvent) {
    const clickedInside = event.target instanceof Element && event.target.closest('.profile-container');
    if (!clickedInside) {
      this.dropdownVisible = false;
    }
  }

  login() {
    this.router.navigate(['/login'], { queryParams: { next: this.router.url } });
  }
  logout() {
    this.authS.logout();
  }
}
