<div class="container">
    <div class="hero-section">
        <div class="big-text">Car <br> repair <br> made easy</div>
        <div class="small-text">
            <div>Hassle-free car repair</div>
            <button type="button" [routerLink]="'/services'">See our services</button>
        </div>
        <div class="photo">
            <img src="/assets/car-Photoroom.png" alt="">
        </div>
    </div>


    <div class="benefits">
        <div class="heading">Simply book online and we will handle the rest</div>

        <div class="flex-container">
            <div *ngFor="let beneft of benefits" class="benefit">
                <div class="title">{{beneft.title}}</div>
                <div class="description">{{beneft.description}}</div>
                <div class="arrow">
                    <svg preserveAspectRatio="xMidYMid meet" data-bbox="21.1 72.3 158.1 49.8"
                        xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="21.1 72.3 158.1 49.8"
                        data-type="shape" role="presentation" aria-hidden="true" aria-label="">
                        <g>
                            <path d="M154.8 72.3l-2 2L174 94.9 21.1 95v2.7l153-.1-22.2 22.5 1.9 2 25.4-25.8-24.4-24z">
                            </path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>

    <div class="how_it_works">
        <div class="heading">
            How it works
        </div>
        <div class="sub-text">
            Car repair, made easy in 4 steps
        </div>
        <div *ngFor="let step of steps; let i=index" class="step">
            <div class="icon">
                <img [src]="step.icon" alt="">
            </div>
            <div class="text">
                {{step.text}}<br>{{step.subText}}
            </div>
            <button type="button">Step {{i+1}}</button>
        </div>
    </div>

    <div class="help">
        <div class="heading">Need a hand understanding your insurance claim needs?</div>
        <div class="description">Our in-house experts knows cars, our garages, insurance claims and our drivers inside
            out</div>
    </div>

    <div class="faq-section">
        <h2 class="title">Common questions about booking at Fixter</h2>
        <div class="faq-container">
            <div *ngFor="let faq of FAQS; let i =index" class="accordion">
                <div class="header" (click)="openedFaq == i ? openedFaq = -1 : openedFaq = i">
                    <div class="text">{{faq.title}}</div>
                    <div class="icon" [ngClass]="{'rotated': openedFaq == i}">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z" fill="#0F0F0F"/>
                        </svg>
                    </div>
                </div>
                <div class="content" [ngClass]="{'opened': openedFaq == i}">
                    {{faq.description}}
                </div>
            </div>
        </div>
    </div>

</div>