import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  phoneNumber: string = "";
  otp: string = "";

  otpSent: boolean = false;

  private readonly returnUrl: string = "";

  constructor(private readonly router: Router, private readonly authS: AuthService, private readonly route: ActivatedRoute) {
    this.returnUrl = this.route.snapshot.queryParams['next'] || '/';
    this.phoneNumber = this.route.snapshot.queryParams['phone'] || "";
  };

  getOtp() {
    setTimeout(() => {
      this.otpSent = true;
    }, 300);
  }

  onLogin() {
    this.authS.login(Number(this.otp), Number(this.phoneNumber));
    this.router.navigate([this.returnUrl]);
  }

  routeToRegister() {
    this.router.navigateByUrl('/register');
  }
}
