<div class="success-card">
    <button class="dismiss" type="button">×</button>
    <div class="header">
        <div class="image">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                </g>
            </svg>
        </div>
        <div class="content">
            <span class="title">Service Booked</span>
            <p class="message">Thank you for your purchase. Someone from our team will get in touch with you shortly.
            </p>
        </div>
    </div>
</div>