<article class="service-scroller">
    <ng-container *ngFor="let service of services; let i =index">
        <div *ngIf="!service.disabled; else disabledService" class="service" (click)="updateWorkshopList(service.id); activeIndex=i"
            [ngClass]="{'active': i==activeIndex, 'disabled': service.disabled}">
            <img [src]="service.iconUrl" alt="">
            {{service.name}}
        </div>
        <ng-template #disabledService>
            <div class="service"
                [ngClass]="{'active': i==activeIndex, 'disabled': service.disabled}">
                <img [src]="service.iconUrl" alt="">
                {{service.name}}
            </div>
        </ng-template>
    </ng-container>
</article>

<div class="container">
    <section class="workshops-list">
        <div *ngFor="let i of workshops" class="workshop">
            <img src="/assets/backgrounds/hero-section-bg.jpg" alt="">
            <div class="workshop-info">
                <h1 class="heading">{{i.name}} {{i.pincode}}<span>Know More</span></h1>
                <p class="description">{{i.description}}</p>
                <ul class="details">
                    <li><strong>Location:</strong>{{userSelectionS.getUserLocation()?.city}}</li>
                    <li><strong>Working Hours:</strong> 9 AM - 5 PM</li>
                </ul>
                <hr class="divider">
                <div class="bottom">
                    <span class="price">₹{{i.price}}</span>
                    <button type="button" class="continue-btn" (click)="bookService(i)">Book Service</button>
                </div>
            </div>
        </div>
    </section>

    <div class="vehicle-selector-container">
        <div *ngIf="!selectVehicle" class="row">
            <div class="col-12 car__img pd-3">
                <img class="img-fluid mx-auto" [src]="carImg" alt="car-img">
            </div>
            <div class="col car__name">
                {{vehicleDetail.vehicle_name}}
            </div>
            <hr style="border: none; height: 1px; background: lightgray; margin: 15px 0;">
            <div class="col-12 col-md-4">
                <button class="car__change-btn learn-more">
                    <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                    </span>
                    <span class="button-text">Change Vehicle</span>
                </button>
            </div>
        </div>
    </div>
</div>