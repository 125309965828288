import { Injectable } from '@angular/core';
import { MAPPING } from '../dataJsons/workshop-service-mappings';
import { WORKSHOPS, WORKSHOPS_BY_ID } from '../dataJsons/workshops';

export type WorkshopByService = {
  id: number;
  serviceId: number;
  name: string;
  price: number;
  description: string;
  pincode: number;
}

@Injectable({
  providedIn: 'root'
})
export class WorkshopService {

  constructor() { };

  getWorkshopsByService(serviceId: number): WorkshopByService[] {
    const workshops = MAPPING.filter((mapping) => mapping.serviceId === serviceId);
    const workshopInfo = workshops.map((workshop) => ({ serviceId: serviceId, price: workshop.price, ...WORKSHOPS_BY_ID[workshop.workshopId] }));
    return workshopInfo;
  }

  getWorkShopById(workshopId: number) {
    return WORKSHOPS_BY_ID[workshopId];
  }

}
