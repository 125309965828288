<section class="hero-section">
</section>

<div class="_3_K4a" style="width: 55%;">
    <div class="_5KP_r">
        <div class="_3gfK1"><img loading="lazy"
                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/FF3733C0-2200-4562-A654-5C44240968CE.svg"
                alt="arrow" style="transform:rotate(180deg)" class="_3tDbw">
            <div class="_2pBzB">
                <div class="_2e9rI">
                    <div id="OUR_SERVICES_NAVIGATOR" class="-E5Tr" style="cursor:pointer">Our Services</div>
                    <div id="GOMECHANIC_CURATED_CUSTOM_SERVICE_NAVIGATOR" class="-E5Tr" style="cursor:pointer">Curated
                        Custom Service</div>
                    <div id="GOMECHANIC_MANSOON_SHIELD_NAVIGATOR" class="-E5Tr" style="cursor:pointer">Winter Services
                    </div>
                    <div id="HOW_GOMECH_WORKS_NAVIGATOR" class="-E5Tr" style="cursor:pointer">How GoMechanic Works?
                    </div>
                    <div id="RATING_REVIEWS_NAVIGATOR" class="-E5Tr" style="cursor:pointer">Rating &amp; Reviews</div>
                    <div id="FREQUENTLY_ASKED_QUESTIONS_NAVIGATOR" class="-E5Tr" style="cursor:pointer">Frequently asked
                        questions</div>
                    <div id="DOWNLOAD_APP_NAVIGATOR" class="-E5Tr" style="cursor:pointer">Download App</div>
                    <div id="GOMECH_SERVICE_GUIDE_NAVIGATOR" class="-E5Tr" style="cursor:pointer">GoMechanic Service
                        Guide
                    </div>
                    <div id="GOMECH_PRICE_LIST_NAVIGATOR" class="-E5Tr navigationSelected" style="cursor:pointer">Price
                        List
                    </div>
                </div>
            </div><img loading="lazy"
                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/FF3733C0-2200-4562-A654-5C44240968CE.svg"
                class="_3tDbw" alt="arrow">
        </div>
    </div>
    <div class="_2yMax " id="OUR_SERVICES">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Car Services Available In Gurgaon</h2>
        </div>
        <div class="_3EX4H">Get free pick and drop with professional car repair, periodic car service, wheel care
            services,
            cashless insurance claim and much more in the Millennium City; Gurgaon.</div>
        <div class="_3K6hn" itemscope="" itemtype="http://schema.org/SiteNavigationElement"><a
                href="https://gomechanic.in/gurgaon/car-repair/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/1.png"
                            alt="Periodic Services Gurgaon"><span itemprop="name">Periodic Services</span></div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-ac-repair/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/5.png"
                            alt="AC Service &amp; Repair Gurgaon"><span itemprop="name">AC Service &amp; Repair</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-battery-replacement/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/battery-v3.svg"
                            alt="Batteries Gurgaon"><span itemprop="name">Batteries</span></div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-tyre-replacement/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/tyre-v3.svg"
                            alt="Tyres &amp; Wheel Care Gurgaon"><span itemprop="name">Tyres &amp; Wheel Care</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-dent-paint/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/denting-painting-v3.svg"
                            alt="Denting &amp; Painting Gurgaon"><span itemprop="name">Denting &amp; Painting</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-detailing/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/8.png"
                            alt="Detailing Services Gurgaon"><span itemprop="name">Detailing Services</span></div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-cleaning-service/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xhdpi/4.png"
                            alt="Car Spa &amp; Cleaning Gurgaon"><span itemprop="name">Car Spa &amp; Cleaning</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/custom-repair/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_3N7qf">New</div>
                    <div class="_1g789 _2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/Car-Inspection.png"
                            alt="Car Inspections Gurgaon"><span itemprop="name">Car Inspections</span></div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/windshield-glass-replacement/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/10.png"
                            alt="Windshields &amp; Lights Gurgaon"><span itemprop="name">Windshields &amp; Lights</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-suspension-fitment/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/Suspension-_-Fitments.png"
                            alt="Suspension &amp; Fitments Gurgaon"><span itemprop="name">Suspension &amp;
                            Fitments</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-lights-replacement/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_3N7qf">New</div>
                    <div class="_1g789 _2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/Clutch-_-Bumpers.png"
                            alt="Clutch &amp; Body Parts Gurgaon"><span itemprop="name">Clutch &amp; Body Parts</span>
                    </div>
                </div>
            </a><a href="https://gomechanic.in/gurgaon/car-insurance/maruti-suzuki-swift/petrol"
                style="text-decoration:none;color:#FFF;justify-self:center" itemprop="url">
                <div class="_34TE3">
                    <div class="_2hv1J"><img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/insurance-v3.svg"
                            alt="Insurance Claims Gurgaon"><span itemprop="name">Insurance Claims</span></div>
                </div>
            </a></div>
    </div>
    <div class="_2yMax ">
        <div class="_2hpxe"></div>
        <div class="_3DmPt"><span></span></div>
        <div class="_1xH5z"><a href="/miles-membership" style="text-decoration:none;color:#FFF" itemprop="url"><img
                    placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                    loading="lazy"
                    src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Warranty/miles.png"
                    class="_1aVFu" alt="miles"></a></div>
    </div>
    <div class="_2yMax " id="GOMECHANIC_CURATED_CUSTOM_SERVICE">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Curated Custom Services</h2>
        </div>
        <div class="_3TRQC"></div>
        <div class="AOIPe">
            <div style="margin-top: -42px;"><img class="_1sJhO"
                    src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow">
            </div>
            <div class="_2wbaY">
                <div class="_28n3g" style="grid-template-columns: repeat(13, 1fr); display: grid;"><a
                        href="/gurgaon/car-battery-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/battery_image_for_curated_section_large/liveguard-battery-140.png"
                            class="_1fciT" alt="Batteries">
                        <div class="_1bZsX">SALE</div>
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Batteries</div>
                        </div>
                    </a><a href="/gurgaon/car-repair" style="text-decoration: none; color: rgb(255, 255, 255);"
                        class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/brakes_2/xxhdpi.png"
                            class="X7zo5" alt="Brakes">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Brakes</div>
                        </div>
                    </a><a href="/gurgaon/car-ac-repair" style="text-decoration: none; color: rgb(255, 255, 255);"
                        class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/ac_parts_2/xxhdpi.png"
                            class="X7zo5" alt="AC Parts">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">AC Parts</div>
                        </div>
                    </a><a href="/gurgaon/car-lights-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/clutch_2/xxhdpi.png"
                            class="_1fciT" alt="Clutch">
                        <div class="_1bZsX">NEW</div>
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Clutch</div>
                        </div>
                    </a><a href="/gurgaon/car-tyre-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/tyres_2/xxhdpi.png"
                            class="_1fciT" alt="Tyres">
                        <div class="_1bZsX">SALE</div>
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Tyres</div>
                        </div>
                    </a><a href="/gurgaon/car-suspension-fitment"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/steering_2/xxhdpi.png"
                            class="X7zo5" alt="Steering">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Steering</div>
                        </div>
                    </a><a href="/gurgaon/car-suspension-fitment"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/suspension_2/xxhdpi.png"
                            class="X7zo5" alt="Suspension">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Suspension</div>
                        </div>
                    </a><a href="/gurgaon/windshield-glass-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/lights_2/xxhdpi.png"
                            class="X7zo5" alt="Lights">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Lights</div>
                        </div>
                    </a><a href="/gurgaon/car-lights-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/body_parts/xxhdpi.png"
                            class="X7zo5" alt="Body Parts">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Body Parts</div>
                        </div>
                    </a><a href="/gurgaon/windshield-glass-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/glasses/xxhdpi.png"
                            class="X7zo5" alt="Glasses">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Glasses</div>
                        </div>
                    </a><a href="/gurgaon/car-cleaning-service"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/car_spa/xxhdpi.png"
                            class="X7zo5" alt="Car Spa">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Car Spa</div>
                        </div>
                    </a><a href="/gurgaon/car-detailing" style="text-decoration: none; color: rgb(255, 255, 255);"
                        class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/car_detailing/xxhdpi.png"
                            class="X7zo5" alt="Car Detailing">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Car Detailing</div>
                        </div>
                    </a><a href="/gurgaon/windshield-glass-replacement"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_1JDAu"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/car_parts_icons/side_mirror/xxhdpi.png"
                            class="X7zo5" alt="Side Mirror">
                        <div class="_3LyRe">
                            <div class="_84ZOJ">Side Mirror</div>
                        </div>
                    </a></div>
            </div>
            <div style="margin-top: -42px;"><img class="_1V7xh"
                    src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow">
            </div>
        </div>
    </div>
    <div class="_2yMax " id="GOMECHANIC_MANSOON_SHIELD">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Get Summer Ready With GoMechanic</h2>
        </div>
        <div class="_2ef8s"></div>
        <div class="AOIPe">
            <div><img class="_1sJhO" src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow"></div>
            <div class="_2wbaY">
                <div class="_28n3g" style="align-items:inherit"><a
                        href="/gurgaon/car-ac-repair/maruti-suzuki-swift/petrol"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_2wrPV"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/retail-carousel/indianewperformance_banner1prob00.jpg%3Fv%3D1728373029.432319?version=1728373030.271585"
                            class="_26tqf" alt="Monsoon">
                        <div class="_3LyRe">
                            <div class="_3Ltov">High Performance AC Service</div>
                        </div>
                    </a><a href="/gurgaon/car-detailing/maruti-suzuki-swift/petrol"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_2wrPV"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/retail-carousel/indianewperformance_banner1prob01.jpg%3Fv%3D1728373011.900229?version=1728373012.601934"
                            class="_26tqf" alt="Monsoon">
                        <div class="_3LyRe">
                            <div class="_3Ltov">Meguiar's Ceramic Coating</div>
                        </div>
                    </a><a href="/gurgaon/car-cleaning-service/maruti-suzuki-swift/petrol"
                        style="text-decoration:none;color:#FFF" class="_2wrPV"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/retail-carousel/indianewperformance_banner1prob02.jpg%3Fv%3D1728373036.889248?version=1728373037.547863"
                            class="_26tqf" alt="Monsoon">
                        <div class="_3LyRe">
                            <div class="_3Ltov">Deep All Round Spa</div>
                        </div>
                    </a><a href="/gurgaon/car-repair/maruti-suzuki-swift/petrol"
                        style="text-decoration: none; color: rgb(255, 255, 255);" class="_2wrPV"><img
                            placeholderimage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/retail-carousel/indianewperformance_banner1prob03.jpg%3Fv%3D1728373020.379476?version=1728373021.220238"
                            class="_26tqf" alt="Monsoon">
                        <div class="_3LyRe">
                            <div class="_3Ltov">Standard Service</div>
                        </div>
                    </a></div>
            </div>
            <div><img class="_1V7xh" src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow"></div>
        </div>
    </div>
    <div class="_2yMax ">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Choose the Workshop Near You</h2>
        </div>
        <div class="_3TRQC">Schedule a Pickup Today!</div>
        <div class="AOIPe">
            <div><img class="_1sJhO" src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow"></div>
            <div class="_2wbaY">
                <div class="_28n3g">
                    <div class="Fk3iR _37Pgs"><img placeholderimage="https://gomechanic.in/addons/878e15c1.png"
                            loading="lazy" src="https://gomechanic.in/addons/878e15c1.png" class="_39Sae" alt="Banner">
                        <div class="_10ZNo">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="rjFkD">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="Fk3iR _37Pgs"><img placeholderimage="https://gomechanic.in/addons/878e15c1.png"
                            loading="lazy" src="https://gomechanic.in/addons/878e15c1.png" class="_39Sae" alt="Banner">
                        <div class="_10ZNo">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="rjFkD">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="Fk3iR _37Pgs"><img placeholderimage="https://gomechanic.in/addons/878e15c1.png"
                            loading="lazy" src="https://gomechanic.in/addons/878e15c1.png" class="_39Sae" alt="Banner">
                        <div class="_10ZNo">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="rjFkD">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="Fk3iR _37Pgs"><img placeholderimage="https://gomechanic.in/addons/878e15c1.png"
                            loading="lazy" src="https://gomechanic.in/addons/878e15c1.png" class="_39Sae" alt="Banner">
                        <div class="_15J0x">
                            <p class="_1k5qo"> +172 more </p>
                            <p class="gmn44">LOCATE US</p>
                        </div>
                        <div class="_10ZNo">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="rjFkD">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div><img class="_1V7xh" src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow"></div>
        </div>
    </div>
    <div class="_2yMax ">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">GoMechanic Guarantee</h2>
        </div>
        <div class="_3TRQC"></div>
        <div class="_11UX-">
            <div class="_2J8mJ _3t1VT" style="background-color: rgb(244, 248, 255);">
                <div class="imIE-">Free Pickup Drop</div>
            </div>
            <div class="_2J8mJ _3t1VT" style="background-color: rgb(252, 245, 254);">
                <div class="imIE-">Genuine Parts</div>
            </div>
            <div class="_2J8mJ _3t1VT" style="background-color: rgb(246, 255, 245);">
                <div class="imIE-">30 Days Warranty</div>
            </div>
            <div class="_2J8mJ _3t1VT" style="background-color: rgb(255, 255, 233);">
                <div class="imIE-">Affordable Prices</div>
            </div>
        </div>
    </div>
    <div class="_2yMax " id="HOW_GOMECH_WORKS">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">How GoMechanic works?</h2>
        </div>
        <div class="_1_1m8">
            <div class="_2mBsG">
                <div class="_3OgNF">
                    <div class="_2zMUa">1</div>
                    <div class="_2aV85"></div>
                </div>
                <div class="_3ibB2">
                    <div class="_3OtVI">Select The Perfect Car Service</div>
                    <div class="j9kuV">From GoMechanic's broad portfolio of services</div>
                </div>
                <div class="_1ba7H"><img
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/Select-The-Perfect-Car-Service.png"
                        alt="Work"></div>
            </div>
            <div class="_2mBsG">
                <div class="_3OgNF">
                    <div class="_2zMUa">2</div>
                    <div class="_2aV85"></div>
                </div>
                <div class="_3ibB2">
                    <div class="_3OtVI">Schedule Free Doorstep Pick-up</div>
                    <div class="j9kuV">We offer free pick up and drop for all services booked</div>
                </div>
                <div class="_1ba7H"><img
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/Schedule-Free-Doorstep-Pick-up.png"
                        alt="Work"></div>
            </div>
            <div class="_2mBsG">
                <div class="_3OgNF">
                    <div class="_2zMUa">3</div>
                    <div class="_2aV85"></div>
                </div>
                <div class="_3ibB2">
                    <div class="_3OtVI">Track Your Car Service Real-Time</div>
                    <div class="j9kuV">We will take care of everything from here!</div>
                </div>
                <div class="_1ba7H"><img
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/track-your-car-service-real-time.png"
                        alt="Work"></div>
            </div>
            <div class="_2mBsG">
                <div class="_3OgNF">
                    <div class="_2zMUa">4</div>
                </div>
                <div class="_3ibB2">
                    <div class="_3OtVI">Earn While We Service</div>
                    <div class="j9kuV">Spread the word! You get Rs.750. Your friends get Rs.750!</div>
                </div>
                <div class="_1ba7H"><img
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/Earn-While-We-Service.png"
                        alt="Work"></div>
            </div>
        </div>
    </div>
    <div class="_1y_i6"></div>
    <div style="grid-row-gap:35px;background-color:#FFF;padding-top:30px;padding-bottom:10px;grid-template-columns:1fr 1fr 1fr 1fr"
        class="_2szL6">
        <div class="_3nM4x" style="padding: 0px;"><span>3 Million+</span><span class="_9otr-">Cars Serviced</span></div>
        <div class="_3nM4x" style="padding: 0px;"><span>15 Lacs+</span><span class="_9otr-">Happy Customers</span></div>
        <div class="_3nM4x" style="padding: 0px;"><span>4.7<img
                    style="width: 1.25rem; height: 1.25rem; margin-left: 0.5rem; margin-top: -5px;"
                    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/star.png"
                    alt="star"></span><span class="_9otr-">Average Rating</span></div>
        <div class="_3nM4x" style="padding: 0px;"><span>850+</span><span class="_9otr-">Touch Points In India</span>
        </div>
    </div>
    <div class="_2yMax " id="RATING_REVIEWS">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">What Car Owners In Gurgaon Say</h2>
        </div>
        <div style="margin-top:1.875rem">
            <div class="glide glide--ltr glide--carousel glide--swipeable" id="reviewCar">
                <div class="glide__track" data-glide-el="track">
                    <ul class="glide__slides"
                        style="transition: transform cubic-bezier(0.165, 0.84, 0.44, 1); width: 5540px; transform: translate3d(-1110px, 0px, 0px);">
                        <li class="glide__slide glide__slide--clone" style="width: 545px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Great Service, friendly staff</div>
                                    <p class="_14oEf">My car's air conditioning wasn't working properly, so I took it to
                                        the
                                        GoMechanic workshop. They diagnosed the problem and fixed it quickly. Now, the
                                        AC
                                        works perfectly. Great service and friendly staff.</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/facebook.png"
                                            class="lu6Dn" alt="Facebook">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Saurabh Gautam</p>
                                            <p class="_2U_vh">Facebook</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - Sector 14, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide glide__slide--clone"
                            style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">I got Efficient service and Expert advice</div>
                                    <p class="_14oEf">I recently had my car's battery replaced at the GoMechanic
                                        workshop.
                                        The service was quick, and the staff was very helpful. They even gave me tips on
                                        how
                                        to maintain the battery. Highly recommend them!</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/google.png"
                                            class="lu6Dn" alt="Google">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Mrinal Bhardwaj</p>
                                            <p class="_2U_vh">Google</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - South City 2, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide glide__slide--active"
                            style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Highly Recommended</div>
                                    <p class="_14oEf">I recently moved to the area and needed a reliable place for car
                                        maintenance. The GoMechanic workshop came highly recommended, and they did not
                                        disappoint. The service was efficient, and the staff was knowledgeable and
                                        friendly.
                                    </p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/twitter.png"
                                            class="lu6Dn" alt="Twitter">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Prateek</p>
                                            <p class="_2U_vh">Twitter</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">New City car Zone, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide" style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Definitely Using Their Services Again</div>
                                    <p class="_14oEf">I booked an appointment at the GoMechanic workshop for a car wash
                                        and
                                        interior cleaning. They did an outstanding job. The car looks and feels brand
                                        new. I
                                        will definitely be using their services again.</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/twitter.png"
                                            class="lu6Dn" alt="Twitter">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Rajesh Gupta</p>
                                            <p class="_2U_vh">Twitter</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - Sohna Rd, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide" style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Impressive Professionalism</div>
                                    <p class="_14oEf">The GoMechanic workshop provided excellent service when I needed a
                                        brake replacement. They were very thorough and ensured everything was in perfect
                                        working order before I left. I'm very impressed with their professionalism.</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/google.png"
                                            class="lu6Dn" alt="Google">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Nitin Chaudhary</p>
                                            <p class="_2U_vh">Google</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - Golf Course Road, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide" style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Quick Tire Replacement, Great Service</div>
                                    <p class="_14oEf">I had a flat tire and needed it fixed urgently. The GoMechanic
                                        workshop was able to accommodate me quickly and had my tire replaced in no time.
                                        Their prompt service and reasonable prices make them the best in town.</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/twitter.png"
                                            class="lu6Dn" alt="Twitter">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Pankaj Sharma</p>
                                            <p class="_2U_vh">Twitter</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - Sector 39, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide" style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Great Service, friendly staff</div>
                                    <p class="_14oEf">My car's air conditioning wasn't working properly, so I took it to
                                        the
                                        GoMechanic workshop. They diagnosed the problem and fixed it quickly. Now, the
                                        AC
                                        works perfectly. Great service and friendly staff.</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/facebook.png"
                                            class="lu6Dn" alt="Facebook">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Saurabh Gautam</p>
                                            <p class="_2U_vh">Facebook</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - Sector 14, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide" style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">I got Efficient service and Expert advice</div>
                                    <p class="_14oEf">I recently had my car's battery replaced at the GoMechanic
                                        workshop.
                                        The service was quick, and the staff was very helpful. They even gave me tips on
                                        how
                                        to maintain the battery. Highly recommend them!</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/google.png"
                                            class="lu6Dn" alt="Google">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Mrinal Bhardwaj</p>
                                            <p class="_2U_vh">Google</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - South City 2, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide glide__slide--clone"
                            style="width: 545px; margin-left: 5px; margin-right: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Highly Recommended</div>
                                    <p class="_14oEf">I recently moved to the area and needed a reliable place for car
                                        maintenance. The GoMechanic workshop came highly recommended, and they did not
                                        disappoint. The service was efficient, and the staff was knowledgeable and
                                        friendly.
                                    </p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/twitter.png"
                                            class="lu6Dn" alt="Twitter">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Prateek</p>
                                            <p class="_2U_vh">Twitter</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">New City car Zone, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="glide__slide glide__slide--clone" style="width: 545px; margin-left: 5px;">
                            <div class="_3VMaw">
                                <div class="_41vpm"><img loading="lazy"
                                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/invertedComma.png"
                                        class="_2ZfUO" alt="Inverted Comma">
                                    <div class="_3K92a">Definitely Using Their Services Again</div>
                                    <p class="_14oEf">I booked an appointment at the GoMechanic workshop for a car wash
                                        and
                                        interior cleaning. They did an outstanding job. The car looks and feels brand
                                        new. I
                                        will definitely be using their services again.</p>
                                    <div class="TK-2w"><img loading="lazy"
                                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/twitter.png"
                                            class="lu6Dn" alt="Twitter">
                                        <div class="_1LES1">
                                            <p class="dDAcE">Rajesh Gupta</p>
                                            <p class="_2U_vh">Twitter</p>
                                        </div>
                                    </div>
                                    <div>
                                        <hr class="_20URP">
                                        <div class="o13LC">Workshop Name</div>
                                        <div class="_3Xe6j">GoMechanic - Sohna Rd, Gurgaon</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <div class="glide__bullets reviewsBullets" data-glide-el="controls[nav]"><button
                            class="glide__bullet userReviewsBullet glide__bullet--active"
                            data-glide-dir="=0"></button><button class="glide__bullet userReviewsBullet"
                            data-glide-dir="=1"></button><button class="glide__bullet userReviewsBullet"
                            data-glide-dir="=2"></button><button class="glide__bullet userReviewsBullet"
                            data-glide-dir="=3"></button><button class="glide__bullet userReviewsBullet"
                            data-glide-dir="=4"></button><button class="glide__bullet userReviewsBullet"
                            data-glide-dir="=5"></button></div>
                </div>
            </div>
        </div>
    </div>
    <div class="_2yMax " id="FREQUENTLY_ASKED_QUESTIONS">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Common Car Service Questions In Gurgaon</h2>
        </div>
        <div style="margin-top:1.875rem">
            <div class="_1UV36">
                <div class="_3QfUP">
                    <div class="_3f-An" aria-expanded="true">
                        <div class="LITgL">How do you address car servicing needs in Gurugram’s extreme weather?</div>
                        <img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/ic_expand_more.svg"
                            class="_2FHWM" alt="Expand">
                    </div>
                    <div class="_3mY1o">From scorching summers to chilly winters, we provide specialized services like
                        AC
                        performance checks and battery inspections to ensure your car runs smoothly year-round.</div>
                </div>
                <div class="_3QfUP">
                    <div class="_3f-An" aria-expanded="false">
                        <div class="LITgL">Are there services tailored for the long drives common in Gurugram?</div><img
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/ic_expand_more.svg"
                            class="_2FHWM" alt="Expand">
                    </div>
                    <div class="_3mY1o">Yes, we offer comprehensive pre-trip inspections, including tire checks, engine
                        tuning, and brake evaluations, to keep your car road-ready for long drives on the highways
                        around
                        Gurugram.</div>
                </div>
                <div class="_3QfUP">
                    <div class="_3f-An" aria-expanded="false">
                        <div class="LITgL">How do you handle pickup and drop-off in Gurugram’s gated societies?</div>
                        <img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/ic_expand_more.svg"
                            class="_2FHWM" alt="Expand">
                    </div>
                    <div class="_3mY1o">Our drivers are trained to handle security protocols. They verify your OTP,
                        click
                        photos, and ensure a hassle-free experience, even in gated communities.</div>
                </div>
                <div class="_3QfUP">
                    <div class="_3f-An" aria-expanded="false">
                        <div class="LITgL">Do you cater to luxury cars frequently seen in Gurugram?</div><img
                            loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/ic_expand_more.svg"
                            class="_2FHWM" alt="Expand">
                    </div>
                    <div class="_3mY1o">Yes, we have dedicated luxury workshops in Gurugram equipped to service high-end
                        vehicles, ensuring expert care and precision.</div>
                </div>
                <div class="_3QfUP">
                    <div class="_3f-An" aria-expanded="false">
                        <div class="LITgL">What makes your services stand out in a competitive market like Gurugram?
                        </div>
                        <img loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/ic_expand_more.svg"
                            class="_2FHWM" alt="Expand">
                    </div>
                    <div class="_3mY1o">We offer transparent pricing, warranty-backed genuine parts, real-time updates,
                        and
                        free pickup/drop-off services, ensuring reliability and trust in every interaction.</div>
                </div>
            </div>
        </div>
    </div>

    <div class="_2yMax ">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Original Spare Parts</h2>
        </div>
        <div class="_3TRQC">Extended Warranty with GoMechanic</div>

        <div class="AOIPe">
            <div style="margin-top: -42px;"><img class="_1sJhO"
                    src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow">
            </div>
            <div class="_2wbaY">
                <div class="_28n3g">
                    <div class="_1wXq- _37Pgs"><img placeholderimage="/addons/878e15c1.png" loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/original_spare_parts_icons/bosch.jpg"
                            class="_2P5xN" alt="bosch">
                        <div class="rjFkD">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="_3StO-">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="_1wXq- _37Pgs"><img placeholderimage="/addons/878e15c1.png" loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/original_spare_parts_icons/valeo.jpg"
                            class="_2P5xN" alt="valeo">
                        <div class="rjFkD">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="_3StO-">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="_1wXq- _37Pgs"><img placeholderimage="/addons/878e15c1.png" loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/original_spare_parts_icons/mahle.jpg"
                            class="_2P5xN" alt="mahle">
                        <div class="rjFkD">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="_3StO-">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="_1wXq- _37Pgs"><img placeholderimage="/addons/878e15c1.png" loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/original_spare_parts_icons/ntn.jpg"
                            class="_2P5xN" alt="ntn">
                        <div class="rjFkD">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="_3StO-">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="_1wXq- _37Pgs"><img placeholderimage="/addons/878e15c1.png" loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/original_spare_parts_icons/sachs.jpg"
                            class="_2P5xN" alt="sachs">
                        <div class="rjFkD">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="_3StO-">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                    <div class="_1wXq- _37Pgs"><img placeholderimage="/addons/878e15c1.png" loading="lazy"
                            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/original_spare_parts_icons/textar.jpg"
                            class="_2P5xN" alt="textar">
                        <div class="rjFkD">
                            <div class="Ygbrs"></div>
                            <div class="_3mBXw"></div>
                        </div>
                        <div class="_3StO-">
                            <div class="_1lDu_"></div>
                            <div class="FbScu"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: -42px;"><img class="_1V7xh"
                    src="https://gomechanic.in/graphics/Icons/rightArrow.svg" alt="Left arrow">
            </div>
        </div>
    </div>
    <div class="_2yMax " id="GOMECH_SERVICE_GUIDE">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Why Choose GoMechanic In Gurgaon?</h2>
        </div>
        <div style="padding-bottom:0;margin-bottom:2rem;margin-top:2rem">
            <div class="smJIH">
                <div style="" class="_1biaQ">
                    <h2 dir="ltr">Car Servicing That Matches Gurgaon’s Pace</h2>
                    <p dir="ltr">Gurgaon. The city that doesn’t stop. Towers rise. Cars rush. Deadlines scream. And you?
                        You
                        just keep moving.</p>
                    <p dir="ltr">Your car? It takes it all. Every speed bump. Every red light. Every endless Gurgaon
                        traffic
                        jam. But when it needs care, where do you turn?</p>
                    <p dir="ltr">GoMechanic.</p>
                    <p dir="ltr">We’re not just a service station. We’re your car’s pit stop. From the quiet lanes of
                        DLF to
                        the chaos near Udyog Vihar, we’ve got you covered. Every time</p>
                    <h4 dir="ltr">Services That Know Gurgaon</h4>
                    <p dir="ltr">Every road here tests your car. Dust. Potholes. Start-stop traffic. It takes a toll.
                        But we
                        keep your car ready. Always.</p>
                    <ul>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Car Detailing (Cleaning, Polishing,
                                    Coating)</strong><br>Dust wins every day in Gurgaon. But it doesn’t win forever. Our
                                detailing studios erase the grime, bring back the shine, and make your car ready for the
                                city again.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Denting &amp; Painting</strong><br>Tight parking
                                lots.
                                Rash drivers. Unavoidable bumps. Gurgaon isn’t kind to your car. But we undo the damage.
                                Seamlessly. No marks. No memory of the dent.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Repairs &amp; Replacements (Engine, Brakes,
                                    Suspension,
                                    Clutch, etc.)</strong><br>Sometimes your car whispers. Sometimes it groans. Pay
                                attention. We do. We repair what’s broken. We replace what’s worn. And we do it right.
                            </p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>AC Service &amp; Repairs</strong><br>Gurgaon heat
                                is
                                cruel. And relentless. A working AC isn’t optional. It’s survival. We make sure it’s
                                always
                                there when you need it.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>General Service</strong><br>The secret to a car
                                that
                                lasts? Regular care. Oil changes, inspections, replacements—we do it all. Gurgaon-ready,
                                every single day.</p>
                        </li>
                    </ul>
                    <h3 dir="ltr">How It Works</h3>
                    <p dir="ltr">In Gurgaon, every minute counts. Servicing your car shouldn’t take hours. Here’s how we
                        make it easy:</p>
                    <ol>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Book Online:</strong> App or web. Done in under a
                                minute.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Confirm Slot:</strong> Details shared on WhatsApp.
                                Instant and clear.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Free Pickup: </strong>Driver arrives. OTP verified.
                                Pictures clicked. Done.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Inspection:</strong> Experts check every corner of
                                your
                                car. Nothing is missed.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Approval:</strong> Estimate shared. You approve. We
                                proceed.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Service: </strong>Repairs. Replacements. Updates
                                sent
                                to you in real-time.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Delivery: </strong>Your car comes back. Stronger.
                                Better. Ready.</p>
                        </li>
                    </ol>
                    <p dir="ltr">Simple? Yes. Fast? Always.</p>
                    <h3 dir="ltr">Why Gurgaon Drives to GoMechanic</h3>
                    <p dir="ltr">Because your car deserves better than a quick fix. It deserves experts. Attention. And
                        care
                        that fits Gurgaon’s pace.</p>
                    <ul>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation">Mechanics who don’t guess. They know.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation">Genuine parts that last. Always warranty-backed.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation">Prices that are clear. No hidden charges. No games.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation">Free pickup and drop-off. Your time? Saved.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation">Real-time WhatsApp updates. You stay in the loop.</p>
                        </li>
                    </ul>
                    <h3 dir="ltr">Gurgaon Speaks in Numbers</h3>
                    <p dir="ltr">Gurgaon’s roads have a vibe. Fast cars. Big dreams. And trust that’s earned with every
                        service. Here’s how Gurgaon shows its belief in us:</p>
                    <ul>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Luxury Workshops: </strong>2. For premium cars that
                                need a little extra TLC.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Non-Luxury Workshops:</strong> 37. For every gaadi,
                                every model, every day.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>B2B Partners:</strong> Classic Car Rental, Orix,
                                Fab
                                Cars, WhizzEV, and more. Big names. Bigger trust.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>EV Servicing:</strong> 4,689 bookings a month.
                                Gurgaon’s zipping into the electric future.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Periodic Service: </strong>10,873 cars every month.
                                Keeping them sorted and smooth for the roads.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>AC Servicing: </strong>7,249 cars a month. Because
                                Gurgaon ki garmi aur traffic? Cooling zaroori hai.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Windshield Repair/Replacement:</strong> 3,624 cars
                                monthly. Clearer views, sharper turns, better rides.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>Other Services: </strong>16,310 cars a month. From
                                tiny
                                tweaks to the big stuff, we handle it all.</p>
                        </li>
                        <li dir="ltr" aria-level="1">
                            <p dir="ltr" role="presentation"><strong>SOS Time:</strong> 24 minutes. Stuck? Bas, ek call
                                away.</p>
                        </li>
                    </ul>
                    <p dir="ltr">This isn’t just Gurgaon’s numbers. It’s proof. Proof that trust, service, and
                        consistency
                        go hand-in-hand here. Every car. Every day. Every road.</p>
                    <ul>
                        <li dir="ltr" style="font-weight: bold;" aria-level="1"><strong><span
                                    style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">Ready
                                    When Gurgaon Calls</span></strong></li>
                    </ul>
                    <p dir="ltr">Every car needs care. Every driver needs a partner.</p>
                    <p dir="ltr">At GoMechanic, we don’t just fix cars. We fix how car servicing is done. Fast.
                        Transparent.
                        Reliable.</p>
                    <p dir="ltr">So, when your car calls, don’t wait. Answer. <strong>Book a service today.</strong></p>
                    <p dir="ltr">Because Gurgaon moves fast. And so do we.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="_2yMax " id="GOMECH_PRICE_LIST">
        <div class="_2hpxe"></div>
        <div class="_3DmPt">
            <h2 class="_8cz6D">Car Services price List in Gurgaon, India 2025</h2>
        </div>
        <div style="padding-bottom:0;margin-bottom:1rem;margin-top:1rem">
            <div>
                <table class="_1PX23">
                    <col class="smFrW">
                    <col class="smFrW">
                    <col class="smFrW">
                    <thead class="_2-e8a">
                        <tr>
                            <td>Services Type</td>
                            <td>Price Starts From (<!-- -->₹ <!-- -->)</td>
                            <td>Savings</td>
                        </tr>
                    </thead>
                    <tbody class="_1B_A4">
                        <tr class="_31F2F">
                            <td>Car Inspection/Diagnostics</td>
                            <td>499</td>
                            <td>15%</td>
                        </tr>
                        <tr class="_31F2F">
                            <td>Inspection</td>
                            <td>499</td>
                            <td>25%</td>
                        </tr>
                        <tr class="_31F2F">
                            <td> Door Glass Replacement </td>
                            <td>700</td>
                            <td>30%</td>
                        </tr>
                    </tbody>
                </table>
                <div class="a1S41"><span class="_1WRX-">This data was last updated on <!-- -->26/1/2025</span>
                    <div class="_3COPo">
                        <div class="_38NYD">SEE MORE</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <section class="service-section">
    <div  class="service" (click)="show=true">
        SERVICES
    </div>
</section> -->

<!-- <app-services-dialog [show]="show"></app-services-dialog> -->