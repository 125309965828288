import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  show: boolean = false;

  @HostListener('document:click', ['$event'])
    closeDropdownOnClickOutside(event: MouseEvent) {
      const clickedInside = event.target instanceof Element && (event.target.closest('.services-container') || event.target.closest('.service'));
      if (!clickedInside) {
        this.show = false;
      }
    }
}
