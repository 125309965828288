import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  // private baseDomain = 'http://localhost:8000/api/v2';
  // private baseDomain = 'https://gaadi/api/v2';
  private baseDomain = 'https://gaadifix.in/api/v2';

  constructor(private readonly http: HttpClient) { };

  private createParams(params: Object) {
    let httpParams = new HttpParams();
    if (params) {
      for (const [k, v] of Object.entries(params)) {
        if (typeof (v) == 'string') {
          httpParams = httpParams.set(k, v);
        } else {
          httpParams = httpParams.set(k, JSON.stringify(v));
        }
      }
    }
    return httpParams;
  }

  private createUrl(url: string) {
    return this.baseDomain + url;
  }

  get(url: string, params?: any) {
    url = this.createUrl(url);
    params = this.createParams(params);
    return this.http.get(url, { params: params });
  }

  post(url: string, data?: any, params?: any) {
    url = this.createUrl(url);
    params = this.createParams(params);
    return this.http.post(url, data, { params: params });
  }

  patch(url: string, data?: any, params?: any) {
    url = this.createUrl(url);
    params = this.createParams(params);
    return this.http.patch(url, data, { params: params });
  }

}
