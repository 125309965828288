import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-services-dialog',
  templateUrl: './services-dialog.component.html',
  styleUrls: ['./services-dialog.component.scss']
})
export class ServicesDialogComponent {
  @Input('show') show: boolean = false;
}
