import { Component } from '@angular/core';
import { CarServicesService } from '../services/car-services.service';
import { Item } from '../dataJsons/car-services';

@Component({
  selector: 'app-home-2',
  templateUrl: './home-2.component.html',
  styleUrls: ['./home-2.component.scss']
})
export class Home2Component {
  availableServices: Item[] = [];

  constructor(private readonly carServices: CarServicesService) { 
    this.availableServices = this.carServices.getCarServices();
  };

}
