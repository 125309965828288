import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { UserLocation, UserSelectionService } from '../services/user-selection.service';

@Component({
  selector: 'app-pincode-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './pincode-input.component.html',
  styleUrls: ['./pincode-input.component.scss']
})
export class PincodeInputComponent {
  pincode: string = "";

  constructor(private readonly http: HttpClient, private readonly userSelectionS: UserSelectionService) { };

  ngOnInit() {
    const selectedLocation = this.userSelectionS.getUserLocation();
    if (selectedLocation) {
      this.pincode = selectedLocation.pincode;
      this.OnPincodeChange();
    }
  }

  OnPincodeChange() {
    const pin = this.pincode.trim();
    const url = `https://api.postalpincode.in/pincode/${pin}`;

    this.http.get(url).subscribe((res: any) => {
      const city = res[0].PostOffice[0].District;
      const state = res[0].PostOffice[0].State;

      const data: UserLocation = {
        city: city,
        state: state,
        pincode: pin
      };
      this.userSelectionS.updateUserLocation(data);
    });
  }

}
