import { Component, EventEmitter, Input, Output } from '@angular/core';

export type selectedSlot = {
  date: string;
  time: string;
}

@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss']
})
export class SlotBookingComponent {
  @Output() slotSelected = new EventEmitter<selectedSlot>();
  @Input('date') selectedDate: string | null = null;
  @Input('time') bookedSlot = "";

  timeSlots = [
    "9:00 AM", "9:30 AM", "10:00 AM", "10:30 AM",
    "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM",
    "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM",
    "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM",
  ];

  book(slot: string) {
    if (!this.selectedDate) {
      alert('Please select a date first');
    } else {
      if (this.bookedSlot === slot) {
        this.bookedSlot = "";
        this.slotSelected.emit({ date: "", time: "" });
      } else {
        this.bookedSlot = slot;
        this.slotSelected.emit({date: this.selectedDate, time: this.bookedSlot});
      }
    }
  }

}
