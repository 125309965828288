import { Component } from '@angular/core';
import { UserSelectionService } from './services/user-selection.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ez-gaadi';
  
  constructor(public userSelectionS: UserSelectionService, private readonly authS: AuthService) { };

  ngOnInit() {
    this.authS.autoLogin();
  }
}
