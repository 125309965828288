import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MmvService } from '../services/mmv.service';
import { FormsModule } from '@angular/forms';
import { UserSelectionService } from '../services/user-selection.service';
import { INSURERS } from '../dataJsons/insurers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-selection-backdrop',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './user-selection-backdrop.component.html',
  styleUrls: ['./user-selection-backdrop.component.scss'],
  providers: [MmvService]
})
export class UserSelectionBackdropComponent {
  searchValue: string = "";

  private readonly selections:any = {
    make: { selectedValue: null, trigger: this.getMakes.bind(this) },
    model: { selectedValue: null, trigger: this.getModels.bind(this) },
    fuelType: { selectedValue: null, trigger: this.getFuelType.bind(this) },
    hasInsurer: { selectedValue: null, trigger: this.getInsurerChoice.bind(this) },
    insurer: {selectedValue: null, trigger: this.getInsurers.bind(this)}
  };

  steps = Object.keys(this.selections);
  activeIndex = 0;

  private allItems:any = [];

  visibleMakes:any  = [];

  constructor(
    private readonly mmvS: MmvService,
    public readonly userSelectionS: UserSelectionService,
    private readonly router: Router,
  ) {
    this.allItems = this.selections[this.steps[this.activeIndex]].trigger();
    this.visibleMakes = this.allItems;
  }

  ngOnInit() {
    document.body.classList.add('no-scroll');
  }

  ngOnDestroy() {
    document.body.classList.remove('no-scroll');
  }

  getMakes() {
    return this.mmvS.getMakes().map((item: any) => { item.name = item.manufacturer_name; return item});
  }
  getModels() {
    return this.mmvS.getModelByMake(this.selections.make.selectedValue.id).map((item:any)=> {item.name = item.model_name; return item});
  }
  getFuelType() {
    return this.mmvS.getFuelTypes();
  }

  getInsurerChoice() {
    return [{name: "Yes", icon: "/assets/tick.png"}, {name: "No", icon: "/assets/cross.png"}]
  }

  getInsurers() {
    return INSURERS;
  }

  onSelection(selectedItem:any) {
    const step = this.steps[this.activeIndex];
    this.selections[step].selectedValue = selectedItem;

    this.moveToNextStep();
    if (this.activeIndex === this.steps.length - 1 && this.selections.hasInsurer.selectedValue.name === "No") {
      this.moveToNextStep();
    }
  }

  private moveToNextStep() {
    this.activeIndex++;
    if (this.activeIndex >= this.steps.length) {
      this.userSelectionS.updateVehicleDetails(this.getUserSelection());
      this.router.navigateByUrl('/services');
      console.log(this.getUserSelection());
      this.userSelectionS.closeSelectionPopup();
      return true;
    }
  
    // Get next list items
    const newList = this.selections[this.steps[this.activeIndex]].trigger();
    // Assign list to show items
    this.allItems = newList;
    this.visibleMakes = newList;

    //Empty the search bar
    this.searchValue = "";

    return true;
  }

  moveToPreviousStep() {
    //Remove current value
    this.selections[this.steps[this.activeIndex]].selectedValue = null;
    this.activeIndex--;

    // Get next list items
    const newList = this.selections[this.steps[this.activeIndex]].trigger();
    // Assign list to show items
    this.allItems = newList;
    this.visibleMakes = newList;

    //Empty the search bar
    this.searchValue = "";

    return true;
  }

  onSearch(event: any) {
    const searchValue = event.target.value.trim();
    if (searchValue) {
      this.visibleMakes = this.allItems.filter((item:any) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      this.visibleMakes = this.allItems;
    }
  }

  getUserSelection() {
    const transformed = Object.keys(this.selections).reduce((acc, key) => {
      acc[key] = this.selections[key].selectedValue; // Extracting the 'name' from each nested object
      return acc;
    }, {} as any);

    transformed['vehicle_name'] = `${this.selections.make.selectedValue.name} ${this.selections.model.selectedValue.name} (${this.selections.fuelType.selectedValue.name})`;
    return transformed;
  }

}
