import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CarServicesService } from '../services/car-services.service';
import { Item } from '../dataJsons/car-services';
import { WorkshopByService, WorkshopService } from '../services/workshop.service';
import { UserSelectionService } from '../services/user-selection.service';
import { HttpService } from '../services/http.service';
import { MODELS } from '../dataJsons/makemodels';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-service-page',
  templateUrl: './service-page.component.html',
  styleUrls: ['./service-page.component.scss']
})
export class ServicePageComponent {
  activeIndex: number = -1;
  services: Item[] = [];

  workshops: WorkshopByService[] = [];

  carImg: string = "";
  vehicleDetail: any = {};

  selectVehicle: boolean = false;

  pincode: number = 0;

  private locationSub: Subscription|null = null;

  constructor(
    private readonly router: Router,
    private readonly carServices: CarServicesService,
    private readonly workshopS: WorkshopService,
    public readonly userSelectionS: UserSelectionService,
  ) {
    this.services = this.carServices.getCarServices();
  };

  ngOnInit() {
    if (this.activeIndex == -1) {
      for (let i = 0; i < this.services.length; i++) {
        if (!this.services[i].disabled) {
          this.activeIndex = i;
          break;
        }
      }

      this.updateWorkshopList(this.services[this.activeIndex].id);
    }
    if (!this.carImg) {
      const savedSelection = this.userSelectionS.getSelectedVehicle();
      if (savedSelection) {
        this.vehicleDetail = savedSelection;
        this.getCarImg();
      } else {
        this.selectVehicle = true;
      }
    }

    this.locationSub = this.userSelectionS.locationChange$.subscribe(pincode => {
      this.pincode = pincode;
      this.updateWorkshopList(this.services[this.activeIndex].id);
    });
  }

  ngOnDestroy() {
    if (this.locationSub) {
      this.locationSub.unsubscribe();
    }
  }


  updateWorkshopList(serviceId: number) {
    this.workshops = this.workshopS.getWorkshopsByService(serviceId);
    if (this.pincode) {
      this.workshops = this.workshops.filter(item => item.pincode == this.pincode);
    }
    // this.activeIndex = serviceId;
  }

  bookService(workshopId: WorkshopByService) {
    const service:any = this.services.find((s) => s.id === this.activeIndex);
    this.userSelectionS.updateSelectedService(workshopId, service);
    this.router.navigateByUrl('/checkout');
  }

  getCarImg() {
    this.carImg = MODELS[this.vehicleDetail.make.id].find((item: any) => item.id == this.vehicleDetail.model.id).icon;
  }
}
