export type Workshop = {
    id: number;
    name: string;
    description: string;
    pincode: string | number;
}

export const WORKSHOPS: Workshop[] = [
    {
        pincode: 121002,
        "id": 1,
        "name": "City Auto Repair",
        "description": "Specializing in engine diagnostics, tire replacement, and routine maintenance. Expert mechanics ensure efficient repairs for all car makes and models. Customer-focused approach guarantees reliable solutions, helping vehicles stay in top condition. Offering fast, affordable, and high-quality services to keep your car running smoothly."
    },
    {
        pincode: 121002,
        "id": 2,
        "name": "Speedy Fix Garage",
        "description": "Speedy Fix Garage provides fast, reliable repairs for all vehicle makes and models. Focused on quick turnaround times, their skilled technicians diagnose issues and perform repairs, from engine fixes to brake replacements. Expect top-quality service and reliable solutions without the long wait. Affordable prices guaranteed."
    },
    {
        pincode: 121002,
        "id": 3,
        "name": "Precision Auto Service",
        "description": "Precision Auto Service specializes in transmission repairs, brake services, and suspension work. Technicians use advanced diagnostic tools to identify issues and offer effective solutions. They provide accurate, cost-effective repairs to keep vehicles in top working condition. Trustworthy, experienced service for both domestic and foreign cars."
    },
    {
        pincode: 121002,
        "id": 4,
        "name": "Expert Car Care",
        "description": "Expert Car Care is a full-service repair shop offering preventive maintenance and diagnostics. From oil changes to major repairs, they handle all issues. Their experienced technicians focus on keeping vehicles running efficiently while ensuring high-quality service. Convenient, fast, and affordable car care to suit all needs."
    },
    {
        pincode: 122002,
        "id": 5,
        "name": "QuickFix Garage",
        "description": "QuickFix Garage offers fast and affordable auto repairs. Specializing in engine repairs and diagnostics, they focus on solving problems quickly. Their expert technicians perform everything from simple fixes to complex repairs, ensuring reliability and high standards of service. Affordable pricing and quick turnarounds are their specialties."
    },
    {
        pincode: 122002,
        "id": 6,
        "name": "A1 Auto Repair",
        "description": "A1 Auto Repair is known for its expertise in electrical system repairs, engine diagnostics, and overall vehicle maintenance. Offering prompt, reliable service, their technicians work with all vehicle makes and models. Whether it's a minor fix or a major repair, A1 Auto Repair delivers top-quality results."
    },
    {
        pincode: 122002,
        "id": 7,
        "name": "Reliant Auto Workshop",
        "description": "Reliant Auto Workshop provides comprehensive car repairs, specializing in suspension, exhaust systems, and brake services. Experienced technicians use modern tools to ensure accurate diagnostics and repairs. Their affordable prices and customer-focused approach make them a trusted choice for vehicle owners looking for reliable and fast repair services."
    },
    {
        pincode: 122002,
        "id": 8,
        "name": "TopGear Car Service",
        "description": "TopGear Car Service offers quality repairs and maintenance for both domestic and foreign vehicles. From routine oil changes to transmission repairs, their technicians deliver precise and affordable solutions. With a focus on customer satisfaction, TopGear ensures your car receives the best care to keep it running smoothly."
    },
    {
        pincode: 122002,
        "id": 9,
        "name": "AutoRev Garage",
        "description": "AutoRev Garage provides expert mechanical services, specializing in engine repairs, bodywork, and transmission issues. Their highly skilled team ensures your car is thoroughly diagnosed and repaired. Committed to high-quality service, they offer affordable prices and efficient turnarounds. AutoRev is the trusted choice for complex automotive repairs."
    },
    {
        pincode: 122002,
        "id": 10,
        "name": "MotorWorks Repair Shop",
        "description": "MotorWorks Repair Shop offers reliable, professional auto repairs, from routine oil changes to more extensive services like engine and transmission work. Their skilled technicians provide thorough diagnostics and quick, affordable repairs, ensuring your vehicle stays in optimal condition. Customer satisfaction is always their top priority."
    }
];

export const WORKSHOPS_BY_ID = WORKSHOPS.reduce((acc: any, workshop) => {
    acc[workshop.id] = workshop;
    return acc;
}, {});