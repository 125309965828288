<div class="backdrop" (click)="userSelectionS.closeSelectionPopup()"></div>
<div class="content">
    <h2 class="title">Select {{steps[activeIndex] | titlecase}}</h2>
    <div class="header">
        <div class="input-container">
            <svg viewBox="0 0 24 24" aria-hidden="true" class="search-icon">
                <g>
                    <path
                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z">
                    </path>
                </g>
            </svg>
            <input id="query" class="input" type="search" placeholder="Search..." [(ngModel)]="searchValue"
                (keyup)="onSearch($event)" />
        </div>
        <button *ngIf="activeIndex > 0" class="back-button" (click)="moveToPreviousStep()">
            <div class="button-box">
                <span class="button-elem">
                    <svg viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z">
                        </path>
                    </svg>
                </span>
                <span class="button-elem">
                    <svg viewBox="0 0 46 40">
                        <path
                            d="M46 20.038c0-.7-.3-1.5-.8-2.1l-16-17c-1.1-1-3.2-1.4-4.4-.3-1.2 1.1-1.2 3.3 0 4.4l11.3 11.9H3c-1.7 0-3 1.3-3 3s1.3 3 3 3h33.1l-11.3 11.9c-1 1-1.2 3.3 0 4.4 1.2 1.1 3.3.8 4.4-.3l16-17c.5-.5.8-1.1.8-1.9z">
                        </path>
                    </svg>
                </span>
            </div>
        </button>
        <hr style="margin-top: 15px; color: white;">
    </div>

    <div class="grid-container">
        <div *ngFor="let i of visibleMakes" class="grid-item" (click)="onSelection(i)">
            <img [src]="i.icon" [alt]="i.name" loading="lazy" />
            <p class="name">{{i.name}}</p>
        </div>
    </div>
</div>