import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { ProfileComponent } from './profile/profile.component';
import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
import { LoginPageComponent } from './auth-pages/login-page/login-page.component';
import { RegistrationPageComponent } from './auth-pages/registration-page/registration-page.component';
import { Home2Component } from './home-2/home-2.component';
import { userSelectionGuard } from './guards/user-selection.guard';
import { Home3Component } from './home-3/home-3.component';

const routes: Routes = [
  { path: '', component: Home3Component },
  { path: 'login', component: LoginPageComponent },
  { path: 'register', component: RegistrationPageComponent },
  { path: 'services', component: ServicePageComponent, canActivate: [userSelectionGuard] },
  { path: 'checkout', component: CheckoutPageComponent },
  { path: 'profile', component: ProfileComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
