import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common';

import { debounceTime } from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import { UserSelectionService } from '../services/user-selection.service';
import { MmvService } from '../services/mmv.service';

@Component({
  selector: 'app-vehicle-selector',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './vehicle-selector.component.html',
  styleUrls: ['./vehicle-selector.component.scss']
})
export class VehicleSelectorComponent {
  @Output('selectionDone') finishEvent = new EventEmitter<string[]>();
  label: string = '';
  wait: boolean = false;
  currStep = 0;

  dataFunctions = [
    { label: 'Manufacturer', fn: this.getMake.bind(this), prevArg: '' },
    { label: 'Model', fn: this.getModel.bind(this), prevArg: '' },
    { label: 'Fuel Type', fn: this.getFuelTypes.bind(this), prevArg: '' },
  ];

  showItems: any[] = [];
  itemList: any[] = [];

  resultArr: string[] = [];

  inputFormControl = new FormControl('');

  constructor(private readonly http: HttpService, private readonly userSelectionS: UserSelectionService, private mmvS: MmvService) {
    const items = localStorage.getItem('items');
    if (items) {
      this.itemList = JSON.parse(items);
    } else {
      this.getMake();
    }
  };
  ngOnInit() {
    this.inputFormControl.valueChanges.pipe(
      debounceTime(200),
    ).subscribe((val: any) => {
      if (val.trim()) {
        this.showItems = this.itemList.filter(item => {
          const inputVal = val.trim().toLowerCase();
          if (item.name.toLowerCase().indexOf(inputVal) != -1) {
            return true;
          }
          return false;
        });
      } else {
        this.showItems = this.itemList;
      }
    });
  }

  getMake() {
    this.itemList = this.mmvS.getMakes().map((obj: any) => {
      obj.name = obj.manufacturer_name;
      obj.clickFn = this.onMakeSelect.bind(this);
      return obj;
    });
    this.showItems = this.itemList;
    // this.http.get('/mmv/manufacturers/').subscribe((res: any) => {
    //     this.label = 'Select Manufacturer';
    //     this.itemList = res.map((obj: any) => {
    //         obj.name = obj.manufacturer_name;
    //         obj.clickFn = this.onMakeSelect.bind(this);
    //         return obj;
    //     });
    //     this.showItems = this.itemList;
    // });
  }

  getModel(makeId: string | number) {
    this.itemList = this.mmvS.getModelByMake(makeId).map((obj: any) => {
      obj.name = obj.model_name;
      obj.showName = true;
      obj.clickFn = this.onModelSelect.bind(this);
      return obj;
    });
    this.showItems = this.itemList;
    this.wait = false;
    // this.http.get('/mmv/models/', { 'make': makeId }).subscribe((res: any) => {
    //     this.label = 'Select Model';
    //     this.itemList = res.map((obj: any) => {
    //         obj.name = obj.model_name;
    //         obj.showName = true;
    //         obj.clickFn = this.onModelSelect.bind(this);
    //         return obj;
    //     });
    //     this.showItems = this.itemList;
    //     this.wait = false;
    // });
  }

  getFuelTypes() {
    this.http.get('/mmv/get_fuel_types/').subscribe((res: any) => {
      this.label = 'Select Fuel Type';
      this.itemList = res.map((obj: any) => {
        obj.id = obj.name.toLowerCase().trim().replaceAll(' ', '_');
        obj.showName = true;
        obj.clickFn = this.onFuelTypeSelect.bind(this);
        return obj;
      });
      this.showItems = this.itemList;
      this.wait = false;
    });
  }

  onBack() {
    if (this.currStep == 0) {
      this.finish();
    } else {
      this.currStep--;
      this.resultArr.pop();
      const args = this.dataFunctions[this.currStep].prevArg || '';
      this.dataFunctions[this.currStep].fn(args);
    }
  }

  onMakeSelect(id: string | number, value: string) {
    if (this.wait) { return false };
    this.wait = true;
    this.resultArr.push(value);
    this.dataFunctions[this.currStep + 1].prevArg = id.toString();
    this.currStep++;
    this.getModel(id);
    return true;
  }


  onModelSelect(id: string | number, value: string) {
    if (this.wait) { return false };
    this.wait = true;
    this.resultArr.push(value);
    this.dataFunctions[this.currStep + 1].prevArg = id.toString();
    this.currStep++;
    this.getFuelTypes();
    return true;
  }

  onFuelTypeSelect(fuelType: string, name: string) {
    if (this.wait) { return false };
    this.wait = true;
    this.resultArr.push(name);
    this.dataFunctions[this.currStep].prevArg = fuelType;
    this.currStep++;
    this.finish();
    return true;
  }

  finish() {
    this.currStep = 0;
    this.finishEvent.emit(this.resultArr);
    this.userSelectionS.updateVehicleDetails(this.resultArr);
  }
}
