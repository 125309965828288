import { CanActivateFn } from '@angular/router';
import { UserSelectionService } from '../services/user-selection.service';
import { inject } from '@angular/core';

export const userSelectionGuard: CanActivateFn = (route, state) => {
  const userSelectionService = inject(UserSelectionService);
  if (userSelectionService.getSelectedVehicle()) {
    return true;
  } else {
    userSelectionService.openSelectionPopup();
    return false;
  }
};
