import { Injectable } from '@angular/core';
import { CAR_SERVICES, Item } from '../dataJsons/car-services';


@Injectable({
  providedIn: 'root'
})
export class CarServicesService {

  constructor() { };

  getCarServices(): Item[] {
    return JSON.parse(JSON.stringify(CAR_SERVICES));
  }

}
