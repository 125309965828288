import { Component, HostListener } from '@angular/core';
import { SelectedServiceWorkshop, UserLocation, UserSelectionService } from '../services/user-selection.service';
import { WorkshopByService } from '../services/workshop.service';
import { HttpService } from '../services/http.service';
import { MODELS } from '../dataJsons/makemodels';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent {
  showSuccessDialog: boolean = false;
  showSlotsDialog: boolean = false;

  carImg: string = "";
  vehicleDetail: any = {};
  locationDetail: UserLocation|null;

  // User Information
  phone = new FormControl();
  userInfo: any = {
    name: "",
    phone: ""
  };

  // Pickup Location Information
  pickupInfo = {
    address: ''
  };

  // Car Information (Make and Model)
  carInfo = {
    make: '',
    model: ''
  };

  // Available Car Makes and Models
  carMakes = ['Honda Accord (2013)', 'Maruti Wagon R (2014)'];

  // Service Information
  selectedService = {
    type: 'Full Car Wash',
    slot: ''
  };

  // Order Summary
  totalCost: number = 500;
  estimatedTime: number = 45;

  selectedServiceWorkshop: SelectedServiceWorkshop;

  leadForm:FormGroup = this.fb.group({
    name: ['', Validators.required],
    phone: ['', Validators.required],
    bookDate: ['', Validators.required],
    slotTime: ['', Validators.required],
    address: ['', Validators.required],
    state: ['', Validators.required],
    city: ['', Validators.required],
    pincode: ['', Validators.required],
    vehicle: ['', Validators.required],
    workshopDetails: this.fb.group({
      name: ['', Validators.required],
      id: ['', Validators.required]
    }),
    serviceDetails: this.fb.group({
      name: ['', Validators.required],
      id: ['', Validators.required]
    }),
    totalAmount: ['', Validators.required],
    gst: ['', Validators.required],
    finalAmount: ['', Validators.required]
  });

  constructor(
    private readonly userSelectionS: UserSelectionService,
    public readonly authS: AuthService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly http: HttpClient
  ) {
    const selectedService = this.userSelectionS.getSelectedService();
    this.selectedServiceWorkshop = selectedService;

    (this.leadForm.controls['workshopDetails'] as FormGroup).patchValue({
      name: selectedService.workshopName,
      id: selectedService.workshopId,
    });
    (this.leadForm.controls['serviceDetails'] as FormGroup).patchValue({
      name: selectedService.serviceName,
      id: selectedService.serviceId
    });

    this.leadForm.patchValue({
      totalAmount: selectedService.price,
      gst: 0,
      finalAmount: selectedService.price
    });

    this.locationDetail = this.userSelectionS.getUserLocation();
    this.leadForm.patchValue({
      city: this.locationDetail?.city,
      pincode: this.locationDetail?.pincode,
      state: this.locationDetail?.state,
    });

    const user = this.authS.getUser();
    if (user) {
      this.userInfo = user;
      this.leadForm.patchValue({
        name: user.name,
        phone: user.phone
      });
    }
  };

  ngOnInit() {
    this.vehicleDetail = this.userSelectionS.getSelectedVehicle();
    this.leadForm.patchValue({
      vehicle: this.vehicleDetail.vehicle_name
    });
    this.getCarImg();
  }

  // Method to handle form submission for user info
  onSubmitUserInfo(): void {
    console.log('User Info:', this.userInfo);
  }

  // Method to handle form submission for pickup location
  onSubmitPickupLocation(): void {
    console.log('Pickup Info:', this.pickupInfo);
  }

  // Method to handle form submission for car info
  onSubmitCarInfo(): void {
    console.log('Car Info:', this.carInfo);
  }

  // Complete the order
  completeOrder(): void {
    console.log('Order completed with the following details:');
    console.log('User Info:', this.userInfo);
    console.log('Pickup Info:', this.pickupInfo);
    console.log('Car Info:', this.carInfo);
    console.log('Service Info:', this.selectedServiceWorkshop);
    console.log('Total Cost:', this.totalCost);
    console.log('Estimated Time of Service:', this.estimatedTime);
    console.log('Payment Mode:', 'Cash');
    // You could add logic to send the data to a backend here
    console.log(this.leadForm.value);
    this.http.post('/api/v1/submit-lead', this.leadForm.value).subscribe(res => {
      console.log("response", res);
    });
    this.showSuccessDialog = true;
    setTimeout(() => {
      this.showSuccessDialog = false;
    }, 2000);
  }

  onSlotSelected(event: any) {
    this.selectedService.slot = `${event.date} ${event.time}`;
    this.leadForm.patchValue({
      bookDate: event.date,
      slotTime: event.time
    });
    this.showSlotsDialog = false;
  }

  getCarImg() {
      this.carImg = MODELS[this.vehicleDetail.make.id].find((item: any) => item.id == this.vehicleDetail.model.id).icon;
  }

  redirectToLogin() {
    this.router.navigate(['/login'], { queryParams: { next: this.router.url, phone: this.phone.value } });
  }
}