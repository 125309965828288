export type Item = {
    id: number
    name: string;
    iconUrl: string;
    disabled: boolean;
};

export const CAR_SERVICES: Item[] = [
    {disabled: true, id: 1, name: "Periodic Services", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/1.png"},
    {disabled: false, id: 5, name: "Denting & Painting", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/denting-painting-v3.svg"},
    {disabled: true, id: 2, name: "AC Service & Repair", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/5.png"},
    {disabled: false, id: 12, name: "Insurance Claims", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/insurance-v3.svg"},
    {disabled: true, id: 3, name: "Batteries", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/battery-v3.svg"},
    {disabled: true, id: 4, name: "Tyres & Wheel Care", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons/tyre-v3.svg"},
    {disabled: true, id: 6, name: "Detailing Services", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/8.png"},
    {disabled: true, id: 7, name:"Car Spa & Cleaning", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xhdpi/4.png"},
    {disabled: true, id: 8, name: "Car Inspections", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/Car-Inspection.png"},
    {disabled: true, id: 9, name: "Windshields & Lights", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/10.png"},
    {disabled: true, id: 10, name: "Suspension & Fitments", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/Suspension-_-Fitments.png"},
    {disabled: true, id: 11, name: "Clutch & Body Parts", "iconUrl": "https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/category_icons_new/xxxhdpi/Clutch-_-Bumpers.png"},
]