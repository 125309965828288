import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export type UserProfile = {
  name: string;
  phone: number | string;
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly USER_KEY = "user_details";

  private readonly loggedIn = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedIn.asObservable();

  constructor(private readonly router: Router) { };

  autoLogin() {
    const user = this.getUser();
    if (user) {
      this.loggedIn.next(true);
    }
  }

  login(otp:number, phone:number) {
    localStorage.setItem(this.USER_KEY, JSON.stringify({ name: "Test User", phone: phone }));
    this.loggedIn.next(true);
  }

  logout() {
    localStorage.removeItem(this.USER_KEY);
    this.loggedIn.next(false);
    this.router.navigate(['/'])
  }

  getUser() {
    const details = localStorage.getItem(this.USER_KEY);
    if (details) {
      return JSON.parse(details);
    }
    return null;
  }
}
