import { Component } from '@angular/core';

@Component({
  selector: 'app-home-3',
  templateUrl: './home-3.component.html',
  styleUrls: ['./home-3.component.scss']
})
export class Home3Component {

  benefits = [
    {
      title: "Garages preferred by insurance companies",
      description: "Our in-house experts make sure that your vehicle is repaired only at workshops preferred by insurance companies as it makes the claim settlement seamless."
    },
    {
      title: "Fair pricing",
      description: "We negotiate competitive rates with garages to offer the best prices, on average 30% cheaper than dealerships",
    },
    {
      title: "Vetted garages",
      description: "We personally select and vet only the best local garages, fully covered by a 12-month warranty on parts and labour.",
    },
    {
      title: "Collection from your door",
      description: "Our fully insured EZRiders offer free pick-up and delivery service, with flexible time slots that suit you.",
    }
  ];

  steps = [
    {
      icon: "/assets/step1.png",
      text: "Book Online",
      subText: "Benefit from our pre-negotiated rates, book a time, and we’ll take care of the rest."
    },
    {
      icon: "/assets/step2.png",
      text: "We collect your car",
      subText: "Our fully insured drivers collect your car, right from your doorstep."
    },
    {
      icon: "/assets/step3.png",
      text: "We fix your car",
      subText: "Our top-rated vetted garages get to work - while we keep you informed."
    },
    {
      icon: "/assets/step4.png",
      text: "We deliver your car ",
      subText: "We bring your car home, safe and sound."
    }
  ];

  FAQS = [
    {
      title: "Who is going to drive my car?",
      description: "All of our drivers are fully insured and your vehicle is always protected. We put our drivers through rigorous vetting and training before they are able to drive your vehicle. All of our drivers must have at least 3 years of driving experience, hold a clean driver's licence, be over the age of 21, capable of driving both manual and automatic vehicles and pass a criminal background check. Your vehicle will be assigned to a driver who is responsible for collecting it from your home, delivering it to the garage and returning your vehicle once all work is completed. Sometimes we might coordinate with a garage for them to send their own driver to collect your vehicle. In this instance, you are protected as you would be if it was a EZGadi driver."
    },
    {
      title: "What garage my car will go?",
      description: `
      One of our in-house experts will factor in the make and model of your car, as well as the work that needs doing. They will then allocate your booking to the most appropriate EZGadi garage in your area, and we’ll email you to confirm.\n
      Here's why only 1 in 20 garages pass our selection criteria:\n
      • We only choose garages registered as a Limited Company, with a minimum of 3 years of trading.\n
      • We carefully choose the garage which is a preferred workshop for the insurance company thus making claim settlement faster and seamless\n
      • We assess each garage's various established affiliations.\n
      • We perform an online and offline audit: EZGadi only selects garages with at least a 4 star customer rating on Google.\n
      • We make sure to visit each garage in person. Our in-house experts evaluate the quality of equipment, staff qualifications, capacity, and technical capabilities of selected servicing centres. EZGadi only works with garages which strictly use manufacturer-equivalent parts.
      • After all requirements are fulfilled, we negotiate preferential terms with each selected (including parts and labour rates), so we can pass it along to our customers.
      `,
    },
    {
      title: "What parts will be used?",
      description: "All parts used in repairs and services carried out by EZGadi are either manufacturer parts or equivalent. This guarantees that only the best quality parts are used in your vehicle. We offer a 12-month warranty, meaning that should anything should go wrong with the parts or labour we’ll take care of it, free of charge."
    },
    {
      title: "How does 12 month warranty work?",
      description: `
        All EZGadi bookings are covered by a 12-month warranty on parts and labour.\n
        If there’s a problem (with either parts or labour) up to 12 months after your booking date, we’ll sort it out for you, completely free of charge.\n
        Just email support@ezgadi.com or call our team on 9769669280 and we’ll take care of it.
      `
    }
  ]

  openedFaq = -1;

}
