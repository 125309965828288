<div class="container">
    <div class="row gap-2">
        <div class="col-4 offset-8">
            <button class="btn back-btn border fs-6" type="button" (click)="onBack()">&larr;&nbsp;back</button>
        </div>
        <div class="col-12">
            <label style="font-weight: 450;">Select&nbsp;{{dataFunctions[currStep]?.label}}</label>
        </div>
        <div class="col-12">
            <input class="input" [formControl]="inputFormControl" type="text" placeholder="Search">
        </div>
        <div class="col-12">
            <div class="row">
                <ng-container *ngFor="let item of showItems">
                    <div class="item col-3 py-3 px-1 border shadow-sm rounded"
                        (click)="item.clickFn(item.id, item.name)"
                    >
                        <div class="px-1"><img [src]="item.icon" [alt]="item.name+'.png'" class="img-fluid mx-auto"></div>
                        <div *ngIf="item.showName" class="item-name fw-medium text-center">{{item.name.replaceAll(' ','\n')}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>