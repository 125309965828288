<!-- footer.component.html -->
<footer class="footer">
    <div class="footer-content">
      <p>&copy; 2025 Ez Gaadi</p>
      <div class="footer-links">
        <a href="/privacy">Privacy Policy</a>
        <a href="/terms">Terms of Service</a>
      </div>
    </div>
  </footer>
  