<!-- https://www.codewithfaraz.com/content/456/create-time-slot-booking-app-using-html-css-and-javascript -->

<div class="container">
    <h1>Select booking slot</h1>
    <div class="date-picker">
        <label for="booking-date">Select a Date:</label>
        <input [(ngModel)]="selectedDate" type="date" id="booking-date">
    </div>
    <div class="time-slot-container">
        <ng-container *ngFor="let slot of timeSlots">
            <div class="time-slot" [ngClass]="{'booked': slot == bookedSlot}" (click)="book(slot)">
                {{slot}}
                <span *ngIf="slot==bookedSlot">(Booked)</span>
            </div>
        </ng-container>
    </div>

    <!-- <div class="booked-slots-container">
        <h2>Booked Slots</h2>
        <table id="booked-slots-table">
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody></tbody>
        </table>
    </div> -->
</div>

<!-- Confirmation Modal -->
<div id="confirmation-modal" class="modal hidden">
    <div class="modal-content">
        <p id="selected-slot"></p>
        <button id="confirm-btn" class="btn">Confirm</button>
        <button id="cancel-btn" class="btn cancel">Cancel</button>
    </div>
</div>

<!-- Cancel Booking Modal -->
<div id="cancel-modal" class="modal hidden">
    <div class="modal-content">
        <p id="cancel-slot-message"></p>
        <button id="confirm-cancel-btn" class="btn cancel">Yes, Cancel</button>
        <button id="close-cancel-btn" class="btn">No</button>
    </div>
</div>