import { Injectable } from '@angular/core';
import { WorkshopByService } from './workshop.service';
import { Item } from '../dataJsons/car-services';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export type SelectedServiceWorkshop = {
  workshopId: number;
  serviceId: number;
  workshopName: string;
  price: number;
  description: string;
  serviceName: string;
  serviceIconUrl: string;
}

export type UserLocation = {
  city: string;
  state: string;
  pincode: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserSelectionService {
  private readonly USER_SELECTION_KEYS = {
    SERVICE: "service",
    VEHICLE: "vehicle_details",
    LOCATION: "location"
  };

  private readonly locationChange = new ReplaySubject<number>();
  locationChange$ = this.locationChange.asObservable();

  private readonly popupVisibility = new BehaviorSubject<boolean>(false);
  popupVisibility$ = this.popupVisibility.asObservable();

  constructor() { };


  getSelectedService(): SelectedServiceWorkshop {
    const item = localStorage.getItem(this.USER_SELECTION_KEYS.SERVICE);
    if (item) {
      return JSON.parse(item);
    }
    return {workshopId: -1, serviceId: -1, workshopName: "", price: 0, description: "", serviceName: "", serviceIconUrl: ""};
  }

  updateSelectedService(workShopDetails: WorkshopByService, serviceDetails: Item) {
    const selection: SelectedServiceWorkshop = {
      workshopId: workShopDetails.id,
      serviceId: serviceDetails.id,
      workshopName: workShopDetails.name,
      price: workShopDetails.price,
      description: workShopDetails.description,
      serviceName: serviceDetails.name,
      serviceIconUrl: serviceDetails.iconUrl
    };
    localStorage.setItem(this.USER_SELECTION_KEYS.SERVICE, JSON.stringify(selection));
  }

  getSelectedVehicle() {
    const item = localStorage.getItem(this.USER_SELECTION_KEYS.VEHICLE);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  updateVehicleDetails(details:any) {
    localStorage.setItem(this.USER_SELECTION_KEYS.VEHICLE, JSON.stringify(details));
  }

  openSelectionPopup() {
    this.popupVisibility.next(true);
  }

  closeSelectionPopup() {
    this.popupVisibility.next(false);
  }

  getUserLocation():UserLocation|null {
    const item = localStorage.getItem(this.USER_SELECTION_KEYS.LOCATION);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }

  updateUserLocation(data: UserLocation) {
    localStorage.setItem(this.USER_SELECTION_KEYS.LOCATION, JSON.stringify(data));
    this.locationChange.next(Number(data.pincode));
  }

}
