import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { ServicesDialogComponent } from './home/services-dialog/services-dialog.component';
import { ServicePageComponent } from './service-page/service-page.component';
import { CheckoutPageComponent } from './checkout-page/checkout-page.component';
import { SlotBookingComponent } from './slot-booking/slot-booking.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuccessDialogComponent } from './checkout-page/success-dialog/success-dialog.component';
import { LoginPageComponent } from './auth-pages/login-page/login-page.component';
import { RegistrationPageComponent } from './auth-pages/registration-page/registration-page.component';
import { NgxSplideModule } from 'ngx-splide';
import { Home2Component } from './home-2/home-2.component';
import { VehicleSelectorComponent } from './vehicle-selector/vehicle-selector.component';
import { HttpClientModule } from '@angular/common/http';
import { UserSelectionBackdropComponent } from './user-selection-backdrop/user-selection-backdrop.component';
import { PincodeInputComponent } from './pincode-input/pincode-input.component';
import { Home3Component } from './home-3/home-3.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    HomeComponent,
    ServicesDialogComponent,
    ServicePageComponent,
    CheckoutPageComponent,
    SlotBookingComponent,
    SuccessDialogComponent,
    LoginPageComponent,
    RegistrationPageComponent,
    Home2Component,
    Home3Component,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSplideModule,
    VehicleSelectorComponent,
    HttpClientModule,
    AppRoutingModule,
    UserSelectionBackdropComponent,
    PincodeInputComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
