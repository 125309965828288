<div class="container">
    <div class="profile">
      <!-- Left Side: Profile Image, Name, and Buttons -->
      <div class="profile-left">
        <div class="profile-img">
          <img class="rounded-circle" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" alt="Profile Image">
        </div>
        <h3 class="profile-name">{{user.name}}</h3>
        
        <!-- Button to toggle forms -->
        <button class="btn" (click)="toggleForm('basic')">Basic Details</button>
        <button class="btn" (click)="toggleForm('address')">Address Details</button>
        <button class="btn" (click)="toggleForm('vehicle')">Vehicle Details</button>
      </div>
  
      <!-- Right Side: Forms for editing details -->
      <div class="profile-right">
        <!-- Basic Details Form -->
        <div *ngIf="formType === 'basic'" class="form-section">
          <h4>Edit Basic Details</h4>
          <form (ngSubmit)="saveProfile()">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" id="name" [(ngModel)]="user.name" name="name" required>
            </div>
            <!-- <div class="form-group">
              <label for="email">Email</label>
              <input type="email" id="email" [(ngModel)]="user.email" name="email" required>
            </div> -->
            <div class="form-group">
              <label for="phone">Phone Number</label>
              <input type="text" id="phone" [(ngModel)]="user.mobile" name="mobile" required>
            </div>
            <button type="submit" class="btn">Save</button>
          </form>
        </div>
  
        <!-- Address Details Form -->
        <div *ngIf="formType === 'address'" class="form-section">
          <h4>Edit Address Details</h4>
          <div class="form-group">
            <label for="address">New Address</label>
            <input type="text" [(ngModel)]="newAddress" name="address" placeholder="Add new address">
          </div>
          <button class="btn" (click)="addAddress()">Add Address</button>
          <ul class="address-list">
            <li *ngFor="let address of user.savedAddresses; let i = index">
              {{ address }} 
              <i class="fa fa-trash" (click)="removeAddress(i)"></i>
            </li>
          </ul>
        </div>
  
        <!-- Vehicle Details Form -->
        <div *ngIf="formType === 'vehicle'" class="form-section">
          <h4>Edit Vehicle Details</h4>
          <div class="form-group">
            <label for="vehicle">New Vehicle</label>
            <input type="text" [(ngModel)]="newVehicle" name="vehicle" placeholder="Add new vehicle">
          </div>
          <button class="btn" (click)="addVehicle()">Add Vehicle</button>
          <ul class="vehicle-list">
            <li *ngFor="let vehicle of user.savedVehicles; let i = index">
              {{ vehicle }} 
              <i class="fa fa-trash" (click)="removeVehicle(i)"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  