<div class="container" [formGroup]="leadForm">
    <div class="billing-details">
        <div class="user-info left-border">
            <div>
                <h2 class="title">Account</h2>
                <p class="subscript">To place and order, Login to existing account or Sign Up</p>
                <div  *ngIf="!(authS.loggedIn$ | async)" class="input-group">
                    <label class="label">Mobile Number</label>
                    <input [formControl]="phone" autocomplete="off" name="mobile" id="mobile" class="input" type="text" placeholder="00000-00000" maxlength="10">
                    <div></div>
                </div>
                <div *ngIf="authS.loggedIn$ | async" class="user-details">
                    {{userInfo.name}} | {{userInfo.phone}}
                </div>
                <div *ngIf="!(authS.loggedIn$ | async)" class="login-btn">
                    <button type="button" (click)="redirectToLogin()">
                        Sign up
                        <div class="arrow-wrapper">
                            <div class="arrow"></div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div class="slot-info left-border">
            <h2 class="title">Date & Time of Service</h2>
            <p class="subscript">Select the desired time slot for service</p>
            <div class="selected-slot">
                Booked Slot:
                <span class="slot-time" [ngClass]="{'red-text': !selectedService.slot}">
                    {{selectedService.slot || "No Slot Selected"}}
                </span>
            </div>
            <button class="select-slot-btn" type="button" (click)="showSlotsDialog=true">
                {{selectedService.slot ? "Change Slot" : "Select Slot"}}
            </button>
        </div>
        <div class="address-info left-border">
            <h2 class="title">Enter Address</h2>
            <p class="subscript">Add a new address or Select address from saved addresses</p>
            <textarea [formControlName]="'address'" placeholder="Enter Address" type="text" name="text" class="input" rows="3"></textarea>
            <div class="city">City: <strong>{{locationDetail?.city}} ({{locationDetail?.pincode}})</strong></div>
        </div>
        <div class="payment-info left-border">
            <h2 class="title">Payment Method</h2>
            <p class="subscript">Select a payment method</p>
            <h2 class="title"><i class="fa-solid fa-money-bill-wave" style="color: #005300;"></i> Cash</h2>
        </div>
    </div>
    <div class="billing-summary">
        <div class="row selected-vehicle">
            <div class="col-12 car__img pd-3">
                <img class="img-fluid mx-auto" [src]="carImg" alt="car-img">
            </div>
            <div class="col car__name">
                {{vehicleDetail.vehicle_name}}
            </div>
        </div>
        <hr style="margin-bottom: 25px; margin-top: 15px; color: lightgray; border-style: solid; border-color: lightgray;">
        <h2>Order Summary</h2>
        <div class="placed-orders">
            <div class="card">
                <img [src]="selectedServiceWorkshop.serviceIconUrl" alt="">
                <div class="item-details">
                    <div class="item-name">{{selectedServiceWorkshop.serviceName}}
                    </div>
                    <div class="workshop-name">{{selectedServiceWorkshop.workshopName}}</div>
                    <div class="item-time"><strong>Estimated Time: </strong>5hrs</div>
                </div>
                <div class="item-cost">₹{{selectedServiceWorkshop.price}}</div>
            </div>
        </div>
        <div class="order-amount amt">
            <span>Order Amount</span>
            <span>₹{{selectedServiceWorkshop.price}}</span>
        </div>
        <div class="tax-amount amt">
            <span>Shipping/GST</span>
            <span>₹0</span>
        </div>
        <hr>
        <div class="total-amount amt">
            <span>Total Amount <br>(Incl. Gst)</span>
            <span>₹{{selectedServiceWorkshop.price}}</span>
        </div>
        <div class="checkout-button" [ngClass]="{'disabled': leadForm.invalid}">
            <button [disabled]="leadForm.invalid" (click)="completeOrder()">Complete Order</button>
        </div>
    </div>
</div>


<div *ngIf="showSuccessDialog" class="success-dialog dialog">
    <app-success-dialog></app-success-dialog>
</div>

<div *ngIf="showSlotsDialog" class="slots-dialog dialog">
    <app-slot-booking
        [date]="selectedService.slot.split(' ')[0]"
        [time]="selectedService.slot.split(' ')[1] + ' ' + selectedService.slot.split(' ')[2]"
        (slotSelected)="onSlotSelected($event)"
    ></app-slot-booking>
</div>