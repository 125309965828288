import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  profileImage = 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg';

  // User data
  user = {
    name: 'John Doe',
    mobile: '1234567890',
    email: 'jon@doe.com',
    savedAddresses: [
      '1234 Elm Street, City, State',
      '5678 Oak Avenue, City, State'
    ],
    savedVehicles: [
      'Toyota Corolla 2020',
      'Honda Civic 2018'
    ]
  };

  // New fields for adding
  newAddress = '';
  newVehicle = '';

  // Form type to toggle between Basic, Address, and Vehicle forms
  formType: string = 'basic';

  constructor(private readonly authS: AuthService) { };

  ngOnInit() {
    const userDetails = this.authS.getUser();
    if (!userDetails) {
      this.authS.logout();
    }
    this.user.name = userDetails.name;
    this.user.mobile = userDetails.phone;
  }

  // Method to toggle forms
  toggleForm(form: string): void {
    this.formType = form;
  }

  // Method to save profile
  saveProfile(): void {
    console.log('Profile saved:', this.user);
    // You can call an API to save the updated data
  }

  // Method to add address
  addAddress(): void {
    if (this.newAddress) {
      this.user.savedAddresses.push(this.newAddress);
      this.newAddress = '';
      console.log('New address added:', this.newAddress);
    }
  }

  // Method to remove address
  removeAddress(index: number): void {
    this.user.savedAddresses.splice(index, 1);
    console.log('Address removed:', index);
  }

  // Method to add vehicle
  addVehicle(): void {
    if (this.newVehicle) {
      this.user.savedVehicles.push(this.newVehicle);
      this.newVehicle = '';
      console.log('New vehicle added:', this.newVehicle);
    }
  }

  // Method to remove vehicle
  removeVehicle(index: number): void {
    this.user.savedVehicles.splice(index, 1);
    console.log('Vehicle removed:', index);
  }
}