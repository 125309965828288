import { Injectable } from '@angular/core';
import { FUEL_TYPES, MAKES, MODELS } from '../dataJsons/makemodels';

@Injectable({
  providedIn: 'root'
})
export class MmvService {

  constructor() { };

  getMakes(): string[] {
    return JSON.parse(JSON.stringify(MAKES));
  }

  getModelByMake(makeId: any): any {
    return JSON.parse(JSON.stringify(MODELS[makeId.toString()]));
  }

  getFuelTypes() {
    return JSON.parse(JSON.stringify(FUEL_TYPES));
  }
}
