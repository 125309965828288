export const INSURERS: any = [
    {
        "name": "Bajaj Allianz General Insurance",
        "icon": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa3x9ohHbvh0r8IRRgR6j-5Piy0ghESNmp4w&s"
    },
    {
        "name": "New India Assurance",
        "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/New_India_Assurance.svg/2000px-New_India_Assurance.svg.png"
    },
    {
        "name": "HDFC ERGO General Insurance",
        "icon": "https://upload.wikimedia.org/wikipedia/commons/3/3e/HDFC-Ergo-logo.png"
    },
    {
        "name": "ICICI Lombard General Insurance",
        "icon": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRSzLx-4lTIkgBq4HoAC_0PkBVSm6IX54RVw&s"
    },
    {
        "name": "SBI General Insurance",
        "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/SBI-logo.svg/500px-SBI-logo.svg.png"
    },
    {
        "name": "Reliance General Insurance",
        "icon": "https://logowik.com/content/uploads/images/reliance-general-insurance3594.logowik.com.webp"
    },
    {
        "name": "Tata AIG General Insurance",
        "icon": "https://seeklogo.com/images/T/tata-aig-logo-89A1450A02-seeklogo.com.png"
    },
    {
        "name": "Aditya Birla Insurance",
        "icon": "https://upload.wikimedia.org/wikipedia/en/thumb/7/75/Aditya_Birla_Group_Logo.svg/640px-Aditya_Birla_Group_Logo.svg.png"
    },
    // {
    //     "name": "Oriental Insurance",
    //     "icon": "https://upload.wikimedia.org/wikipedia/commons/5/53/Oriental_Insurance_Logo.png"
    // },
    // {
    //     "name": "Kotak Mahindra General Insurance",
    //     "icon": "https://upload.wikimedia.org/wikipedia/commons/7/73/Kotak_Mahindra_General_Insurance_logo.png"
    // }
];