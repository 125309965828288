import { CAR_SERVICES } from "./car-services";
import { WORKSHOPS } from "./workshops";

type WorkshopServiceMapping = {
    workshopId: number;
    serviceId: number;
    price: number;
};

export const MAPPING: WorkshopServiceMapping[] = [];

for (let workshop of WORKSHOPS) {
    for (let service of CAR_SERVICES) {
        if (service.disabled) {
            continue;
        }
        if (Math.random() < 0.5 || true) {
            MAPPING.push({
                workshopId: workshop.id,
                serviceId: service.id,
                price: Math.floor(Math.random() * (2000 - 500 + 1)) + 500
            });
        }
    }
}